import { Col, Input, message, Modal, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import ReusableTable from '../../../common/tables/Table';
import { useEffect, useMemo, useState } from 'react';
import { addNewLenderToDealAction, getAllLendersAction, getAllLendersInModalAction, getDealsByLenderAction } from '../../../redux/actions/lenderAction';
import { IoIosAddCircleOutline } from 'react-icons/io';
import moment from 'moment';
import AddNewLenderConfirmModal from '../../tabScreens/DealsScreen/InduvidualDealTabs/AddNewLenderConfirmModal';
import { toast } from 'react-toastify';
import { MdCancel, MdDone } from 'react-icons/md';

const AddNewLenderToDeal = ({ deal, showAddNewLenderToDealModal, setShowAddNewLenderToDealModal }) => {
  const { role } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.auth);
  const [position, setPosition] = useState('');
  const [credit, setCredit] = useState('');
  const [amount, setAmount] = useState('');
  const [monthlyMin, setMonthlyMin] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddNewLenderConfirmModal, setShowAddNewLenderConfirmModal] = useState(false);
  const [selectedNewLender, setSelectedNewLender] = useState(null);
  const [lenderTableData, setLenderTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLender, setSelectedLender] = useState(null);

  const dispatch = useDispatch();
  const { loading: allLenderLoading, lenders } = useSelector(state => state.getAllLenders);

  const handleLenderClick = (lender) => {
    setSelectedLender(lender);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLender(null);
  };

  useEffect(() => {
    dispatch(getAllLendersInModalAction(userId , deal._id));
  }, []);

  useEffect(() => {
    let filteredData = lenders;

    if (credit) {
      filteredData = filteredData?.filter(lender => lender?.avg_daily_balance >= credit);
    }

    if (amount) {
      filteredData = filteredData?.filter(lender => lender?.max_funding_amount >= amount);
    }

    if (monthlyMin) {
      filteredData = filteredData?.filter(lender => lender?.monthly_minimums >= monthlyMin);
    }

    if (position) {
      filteredData = filteredData?.filter(lender => lender?.position_toWilling?.includes(position));
    }

    if (searchTerm) {
      filteredData = filteredData?.filter(lender =>
        lender?.lender_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    const lenderData = [];
    filteredData?.forEach((lender) => {
      console.log("IS Added ---- " , lender?.added , " Lender Name " ,lender?.lender_name)
      lenderData.push({
        lender: {
          company: lender?.lender_name,
          ceo: `${lender?.owner_firstName} ${lender?.owner_lastName}`,
          ceoFirstName: lender?.owner_firstName,
          ceoLastName: lender?.owner_lastName,
          ceoMail: lender?.owner_mail
        },
        isAdded : lender?.added,
        positions: lender?.position_toWilling,
        credit: lender?.avg_daily_balance,
        amount: lender?.max_funding_amount,
        monthlyMin: lender?.monthly_minimums,
        createdAt: lender?.createdAt,
        addButton: lender,
      });
    });
    setLenderTableData(lenderData.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [lenders, position, credit, amount, monthlyMin, searchTerm]);

  const onClose = () => {
    setShowAddNewLenderToDealModal(false);
  };

  const handleAddNewLenderModal = (value) => {
    setSelectedNewLender(value);
    handleSubmit(value);
  };

  const filterLenderColumns = useMemo(
    () => [
      {
        Header: 'Lender',
        accessor: 'lender',
        Cell: ({ value }) => (
          <div className="cursor-pointer">
            <div
              className="text-good-morning-text font-semibold capitalize cursor-pointer"
              onClick={() => handleLenderClick(value)}
            >
              {value.company} / {value.ceoFirstName}
            </div>
            <div>{value.ceoMail}</div>
          </div>
        ),
      },
      {
        Header: 'Positions',
        accessor: 'positions',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => (
          <span>{value ? `$${new Intl.NumberFormat('en-US').format(value)}` : "-"}</span>
        ),
      },
      {
        Header: 'Monthly min',
        accessor: 'monthlyMin',
        Cell: ({ value }) => (
          <span>{value ? `$${new Intl.NumberFormat('en-US').format(value)}` : "-"}</span>
        ),
      },
      {
        Header: 'Add lender to deal',
        accessor: 'addButton',
        Cell: ({ value , row }) => (
          value.added  === true? (
            <MdDone
            className="text-green-600 cursor-pointer ml-5"
            size={30}
            // onClick={() => handleAddNewLenderModal(value)}
          />
          ) : (
            <IoIosAddCircleOutline
              className="text-gray-600 cursor-pointer ml-5"
              size={30}
              onClick={() => handleAddNewLenderModal(value)}
            />
          )
        ),
      }
      
    ],
    []
  );

  const handleSubmit = async (selectedLender) => {
    console.log('selected lender --- ' , selectedLender)
    const result = await dispatch(addNewLenderToDealAction(userId, deal?._id, selectedLender?._id));
    if (result?.status === 1) {
      toast.success('New lender added to deal successfully');
      dispatch(getAllLendersInModalAction(userId , deal._id));
      dispatch(getDealsByLenderAction(userId, deal?._id));
    } else {
      toast.error(result?.message);
    }
  };

  return (
    <Modal
      open={showAddNewLenderToDealModal}
      onCancel={onClose}
      width={1000}
      footer={null}
      centered
      title={<h4>Add Lender</h4>}
    >
      <div className="bg-white rounded-lg py-5 px-7">
        <Input
          placeholder="Search lender by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4"
        />
        <Row gutter={20} >
          <Col xs={6}>
            <label>Positions</label>
            <Input value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Enter Positions" />
          </Col>
          <Col xs={6}>
            <label>Credit</label>
            <Input
              value={credit}
              onChange={(e) => /^[0-9]*$/.test(e.target.value) && setCredit(e.target.value)}
              placeholder="Credit"
            />
          </Col>
          <Col xs={6}>
            <label>Amount</label>
            <Input
              value={amount}
              onChange={(e) => /^[0-9]*$/.test(e.target.value) && setAmount(e.target.value)}
              placeholder="Amount"
            />
          </Col>
          <Col xs={6}>
            <label>Monthly Minimum</label>
            <Input
              value={monthlyMin}
              onChange={(e) => /^[0-9]*$/.test(e.target.value) && setMonthlyMin(e.target.value)}
              placeholder="Enter monthly minimum"
            />
          </Col>
        </Row>
      </div>
      <Spin spinning={allLenderLoading}>
        <ReusableTable columns={filterLenderColumns} data={lenderTableData} />
      </Spin>
    </Modal>
  );
};

export default AddNewLenderToDeal;
