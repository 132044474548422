import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import DocumentList from "./DocumentList";
import ReusableTable from '../../../../../common/tables/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Input, Modal, Row, Spin } from 'antd';
import {FiUploadCloud } from "react-icons/fi";
import AddNewLenderToDeal from '../../../../AdminScreens/AddNewLender/AddNewLenderToDeal';
import { getDealsByLenderAction, sendDealToLenderAction } from '../../../../../redux/actions/lenderAction';
import UploadFileModal from './UploadFileModal';
import { getAllFilesAction } from '../../../../../redux/actions/leadsActions';
import { sendDealToSubmissionBoardAction } from '../../../../../redux/actions/dealsAction';
import { toast } from 'react-toastify';
import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { deleteDealLender } from "../../../../../atom/dashboardData/dealsApi";
import { authFundomate, createFundomateDeal, getIndustry, getOwnershipValues, uploadBankStatementFromUrl } from "./LenderAPi";
import IndustryModal from "./IndustryModal";
import PdfPreviewModal from "./PdfPreviewModal";

export default function AgentDealLender() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dealsLenderTableData, setDealsLenderTableData] = useState([]);
    const [showAddNewLenderToDealModal, setShowAddNewLenderToDealModal] = useState(false);
    const [showFileuploadModal, setShowFileuploadModal] = useState(false);
    const [allFilesData, setAllFilesData] = useState([])
    const [template, setTemplat] = useState("Greetings, \nPlease see attached deal.")
    const [isEditing, setIsEditing] = useState(false)
    const [subjectTemplate, setSubjectTemplate] = useState("Deal Attachments from BizFund");
    const [individualButtonLoading , setIndividualbuttonloading] = useState(false)
     const [isModalOpen, setModalOpen] = useState(false);
     const [loading, setLoading] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [ownership, setOwnership] = useState([]);
    const [isOwnerModalOpen, setOwnerModalOpen] = useState(false);
    const [isModalForm , setModalForm] = useState(false)
    const [submitLoading , setSubmitLoading ] = useState(false)
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [selectedOwnership, setSelectedOwnership] = useState("");
    const [dealProfId , setDealProfId] = useState()
    const [showPdfPreviewModal, setShowPdfPreviewModal] = useState(false);
    const [selectedPdfIndex, setSelectedPdfIndex] = useState(null);
    const [formValues, setFormValues] = useState({
        dbaName: "",
        taxId: "",
        inceptionMonth: "",
        inceptionYear: "",
        requestedAmount: "",
        state: "",
        naicsId: "",
        mccId: "",
        ownerSsn: "",
        ownerZip: "",
        companyZip: "",
      });
      const [showModal, setShowModal] = useState(false);
      const { role } = useSelector((state) => state.auth);
      const { userId } = useSelector((state) => state.auth);
      const { selectedTab: tabSelected } = useSelector((state) => state.auth);
      const { loading: allDealsLenderLoading, dealsLender } = useSelector(state => state.getDealsByLender);
      const { loading: sendDealToSBLoading } = useSelector(state => state.sendDealToSubmissionBoard);
      const { deal } = location.state || {};
      const [uploadFileLoading, setUploadFileLoading] = useState({});
    
      const handleModalClose = () => setShowModal(false);
    const handleModalOpen = () => setShowModal(true);

    const handleSubjectChange = (e) => {
        setSubjectTemplate(e.target.value);
    };

   

    const getAllFiles = async () => {
        const result = await dispatch(getAllFilesAction(userId, deal?._id));
        if (result?.status === 1) {
            setAllFilesData(result?.files)
        } else {
            setAllFilesData([])
        }
    }

    const handleSendClick = async (lenderId) => {
        setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: true }));
        try {
            console.log("All files data -- " , dealProfId)
            const data = await getIndustry(setLoading);
            setIndustries(data);
            setModalOpen(true);
            // setShowModal(true)
        } catch (error) {
            console.error("Error fetching industries:", error);
            toast.error("Failed to fetch industries. Please try again.");
        } finally {
            setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: false }));
        }
    };
    const handleCompanyClick = async (industry) => {
        setModalOpen(false)
        console.log("Selected Industry -- " , industry )
        setSelectedIndustry(industry)
        try {
            const data = await getOwnershipValues(setLoading);
           
            setOwnership(data);
            setOwnerModalOpen(true);
        } catch (error) {
            console.error(error);
        }
};
const handlePreview = (index) => {
    setShowPdfPreviewModal(true);
    setSelectedPdfIndex(index);
};
useEffect(() => {
    console.log("useEffect called");
    console.log("Updated dealProfId: ", dealProfId);
  }, [dealProfId]);


const handleFormSubmit = async () => {
    setSubmitLoading(true); 
  
    if(formValues.taxId.length != 9){
        alert("Length of Tax Id should be 9")
        return ;
    }
    const dealData = {
      companyEmail: deal.email,  // 'test3@gmail.com' ,//  deal.email,
      companyName: deal.company_name,
      dbaName: formValues.dbaName,
      companyAddress: deal.street,
      companyCity: deal.city,
      companyState: formValues.state,
      companyZip: formValues.companyZip,
      companyPhone: `+1${deal.phone}`,
      taxId: formValues.taxId,
      inceptionMonth: formValues.inceptionMonth,
      inceptionYear: formValues.inceptionYear,
      requestedAmount: formValues.requestedAmount,
      industry: selectedIndustry,
      naicsId: "",
      mccId: 6,
      ownershipType: selectedOwnership,
      owners: [
        {
          ownerFirstName: deal.firstName,
          ownerLastName: deal.lastName,
          ownerAddress: deal.street,
          ownerCity: deal.city,
          ownerState: "US",
          ownerZip: formValues.ownerZip,
          ownerPhone: `+1${deal.phone}`,
          ownerSsn: formValues.ownerSsn,
          ownerDateOfBirth: "10-22-2000",
        },
      ],
    };
  
    console.log("Deal data --- ", dealData);

  
    try {
      const data = await createFundomateDeal(dealData);
      alert("Deal sent to Fundomate");
      console.log("Deal Profile Id " , data.dealProfileId)
    setDealProfId(data=> {return data;})
      console.log("UseState " , dealProfId)
      setShowModal(true)
      setDealProfId(data.dealProfileId)
      setModalForm(false) 
    } catch (error) {
      console.error(error);
      alert("Deal already sent!");
    } finally {
        console.log("Finally called ")
        setModalForm(false) 
      setSubmitLoading(false); // Reset loading state after the operation is completed
    }
  };
const handleChange = (e) => {
    console.log("e - " , e)
    const { name, value } = e.target;
    setFormValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

const handleOwnershipClick = async (industry) => {
    setOwnerModalOpen(false)
    setModalForm(true)
    console.log("Selected Ownership --- ",  industry);
    setSelectedOwnership(industry)
    console.log("Deal Data ---- " , deal.company_name    )
    // const dealData  =   {
    //     "companyEmail": deal.email,
    //     "companyName": deal.company_name,
    //     "dbaName": "Example DBA",
    //     "companyAddress": deal.street,
    //     "companyCity": deal.city,
    //     "companyState": "-",
    //     "companyZip": "-",
    //     "companyPhone": deal.phone,
    //     "taxId": "123456789",
    //     "inceptionMonth": 0,
    //     "inceptionYear": 0,
    //     "requestedAmount": 0,
    //     "industry": "Retail",
    //     "naicsId": "",
    //     "mccId": 6,
    //     "ownershipType": "SOLE_PROPRIETOR",
    //     "owners": [
    //       {
    //         "ownerFirstName": deal.firstName,
    //         "ownerLastName": deal.lastName,
    //         "ownerAddress": deal.street,
    //         "ownerCity":  deal.city,
    //         "ownerState": "-",
    //         "ownerZip": "-",
    //         "ownerPhone": deal.phone,
    //         "ownerSsn": "-",
    //         "ownerDateOfBirth": "-"
    //       }
    //     ]
    //   }
};

const handleUploadFundomateDoc = async (fileLink , endpoint) => {
    try{
        setUploadFileLoading((prev) => ({   ...prev,
            [fileLink]: { ...prev[fileLink], [endpoint]: true },
          }));
         console.log("Handle Upload Fundomate function called "  , dealProfId)
         if(!dealProfId)
         alert("Failed to create Deal")
        const data = await uploadBankStatementFromUrl(dealProfId , fileLink , endpoint).then(() => alert("Document Sent!"))
        console.log('upload Fundamate --- ' , data )
        
    }catch(e) {
        console.log("Error -- " , e)
    }finally{
        setUploadFileLoading((prev) => ({ ...prev,
            [fileLink]: { ...prev[fileLink], [endpoint]: false },
          }));
    }
}

    const confirmDelete = (lenderId) => {
        console.log("Value --- ", lenderId.original.lender.lender_id)
        Modal.confirm({
            title: 'Are you sure you want to remove this lender?',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => handleDeleteLenderFromDeal(lenderId.original.lender.lender_id),
        });
    };

    useEffect(() => {
        dispatch(getDealsByLenderAction(userId, deal?._id));
        getAllFiles()
    }, []);

    useEffect(() => {
        console.log("Deal Lenders ----- " , dealsLender)
        let filteredData = [];
        if (dealsLender?.length > 0) {
            dealsLender?.forEach((lender) => {
                filteredData?.push({
                    lender: {
                        lender_id: lender?.lender_id,
                        company: lender?.lender_name,
                        ceo: `${lender?.owner_firstName}`,
                        ceoFirstName: lender?.owner_firstName,
                        ceoMail: lender?.owner_mail,
                    },
                    position: lender?.posOfWiilng,
                    credit: lender?.credit ?? "N/A",
                    amount: lender?.payment,
                    monthlyMin: lender?.monthly_minimums,
                    status: lender?.status,
                    sendMail: lender?.lender_id,
                    delete: lender?._id,
                    createdAt: lender?.createdAt,
                    mailSent: lender?.mailSent
                });
            });
        }
        setDealsLenderTableData(filteredData);

    }, [dealsLender]);

    const handleDeleteLenderFromDeal = async (lenderId) => {
        console.log(lenderId, "  <--- lenderId", userId, "  <--- User Id", deal?._id, "  <--- Deal Id")
        const result = await deleteDealLender(userId, lenderId, deal?._id)
        // const result = await dispatch(deleteLenderFromDealAction(userId, lenderId , deal?._id));
        if (result?.status === 1) {
            toast.success(result?.data?.message)
            dispatch(getDealsByLenderAction(userId, deal?._id));
        } else {
            toast.error(result?.data?.message)
        }
    }

    const getStatusClassName = (status) => {
        switch (status) {
            case "Pending":
                return "status-pending";
            case "Declined":
                return "status-declined";
            case "Approved":
                return "status-approved";
            default:
                return "";
        }
    };

    const dealsLenderColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Lender',
                accessor: 'lender',
                Cell: ({ value }) => (
                    <div>
                        <div className="text-good-morning-text font-semibold capitalize">{value.company} / {value.ceoFirstName}</div>
                        {/* <div>{value.ceoMail}</div> */}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => (
                    <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
                        <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
                        <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
                    </div>
                ),
            },
            {
                Header: 'Position',
                accessor: 'position',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Credit',
                accessor: 'credit',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Monthly Min',
                accessor: 'monthlyMin',
                Cell: ({ value }) => (
                    <span>{value ? `$${value}` : "-"}</span>
                ),
            },
            {
                Header: 'Send Deal to Lender',
                accessor: 'sendMail',
                Cell: ({ value, row }) => (
                    <div className="flex">
                        <Button
                            loading={individualButtonLoading[value]}
                            onClick={() => {
                                if (row.original.lender.company === "Fundomate") {
                                    handleSendClick(value); // Pass lenderId for loading state
                                } else {
                                    sendDealToLender(value, row);
                                }
                            }}
                            className={`px-4 py-1 rounded focus:outline-none focus:ring-2 focus:ring-opacity-75 ${
                                row.original.mailSent === "true"
                                    ? "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-400 hover:text-white"
                                    : "bg-green-500 text-white hover:bg-green-600 focus:ring-green-400"
                            }`}
                            disabled={row.original.mailSent}
                        >
                            {row.original.mailSent === "true" ? "Sent" : "Send"}
                        </Button>
                    </div>
                ),
            }
            ,
            
            { Header: 'Delete',
                accessor: 'delete',
                Cell: ({ row }) => {
                    const lenderId = row.original.lender_id;
                    const dealId = row.original.deal_id;
                    return (
                        <div className="flex">
                            <DeleteOutlined
                                className="text-gray-600 cursor-pointer"
                                size={20}
                                onClick={() => confirmDelete(row)}
                            />
                        </div>
                    );
                },}
        ];
        return columns;
    }, [role , individualButtonLoading]);

    const handleAddNewLender = () => {
        setShowAddNewLenderToDealModal(true);
    }

    const handleTemplateChange = (e) => {
        setTemplat(e.target.value)
    }

    const handleEditClick = () => {
        setIsEditing(!isEditing)
    }

    const sendDealToAllLender = async () => {
        console.log("Send Deal to all Lenders --- ")
        const result = await dispatch(sendDealToSubmissionBoardAction(userId, deal?._id, template, subjectTemplate));
        console.log("Result --- " , result?.status)
        if (result?.status == 200) {
            console.log("Resulttt --- " , result?.status)
            dispatch(getDealsByLenderAction(userId, deal?._id));
            toast.success(result?.data?.message)
            // navigate('/submission-board')
        } else {
            toast.success(result?.data?.message)
        }
    }

    const sendDealToLender = async (lenderId , row) => {
        console.log("Row --- " , row)
        setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: true }));
        try {
        const result = await dispatch(sendDealToLenderAction({
            leadId: deal?._id,
            user_id: userId,
            template: template,
            mailSubject: subjectTemplate,
            lender_id: lenderId
        }));
        console.log("response ind --- " , result)
        if (result?.status === 1) {
            dispatch(getDealsByLenderAction(userId, deal?._id));
            toast.success(result?.message)
            // alert("Success, " , result?.message)
           
        } else {
            toast.error(result?.message)
            alert(result?.message)
        } }catch (error) {
            console.error("Error sending deal to lender:", error);
            toast.error("An unexpected error occurred.");
        } finally {
            // Reset loading state for the specific button
            setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: false }));
        }
    }

    return (
        <>
            <div className="flex flex-col gap-4 w-full h-[100vh] overflow-y-auto">
                <div className='flex gap-4 min-h-[485px]'>
                    <div className="flex flex-col items-center w-[40%] rounded-lg px-[0.8rem] py-[1rem] bg-white">
                        <div className="flex text-[1.2rem] text-[#0A2759] w-[100%] justify-between font-[500] px-[0.5rem] py-[0.5rem]">
                            Document
                            <Button
                                className="flex gap-2 border border-[#00A762] rounded-lg px-2 py-1.5 items-center text-[1rem]"
                                onClick={() => setShowFileuploadModal(true)}
                            >
                                <FiUploadCloud /> Upload
                            </Button>
                        </div>
                        <DocumentList allFilesData={allFilesData} setAllFilesData={setAllFilesData} dealId={deal?._id} />
                    </div>
                    <div className='w-[60%]'>
                        <div className="bg-white flex justify-between w-full py-5 rounded-tl-md rounded-tr-md">
                            <div className="flex items-center">
                                <div className="ml-8 text-good-morning-text font-bold text-xl">
                                    Lenders
                                </div>
                            </div>
                            {role === 'agents' && tabSelected === 'Submission Board' ?
                                null
                                : <div
                                    className="h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer"
                                    onClick={() => handleAddNewLender()}
                                >
                                    Add new lender
                                </div>}
                        </div>
                        <div className="h-5/6 w-full overflow-x-auto">
                            <Spin spinning={allDealsLenderLoading}>
                                <ReusableTable columns={dealsLenderColumns} data={dealsLenderTableData} maxRow={5} />
                            </Spin>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-[#0A2759] font-[500] w-[100%]">
                    <div className="flex flex-col gap-4 bg-white px-[2rem] py-[1rem] rounded-lg">
                        {/* Template Header */}
                        <div className="flex justify-between gap-2 items-center mt-2 text-[1.2rem]">
                            Template
                            <button
                                className="text-white bg-[#00A762] px-4 py-1 rounded text-[1rem]"
                                onClick={handleEditClick}
                            >
                                {isEditing ? "Save" : "Edit"}
                            </button>
                        </div>

                        {/* Subject Template */}
                        <div className="flex flex-col gap-2">
                            <span className="text-[1rem] font-semibold">Subject</span>
                            <textarea
                                className="flex gap-2 px-3 py-1 h-[50px] rounded-lg border border-[#D0D3D9] outline-none"
                                value={subjectTemplate}
                                onChange={handleSubjectChange}
                                disabled={!isEditing}
                            />
                        </div>

                        {/* Body Template */}
                        <div className="flex flex-col gap-2">
                            <span className="text-[1rem] font-semibold">Body</span>
                            <textarea
                                className="flex gap-2 px-3 py-1 h-[100px] rounded-lg border border-[#D0D3D9] outline-none"
                                value={template}
                                onChange={handleTemplateChange}
                                disabled={!isEditing}
                            />
                        </div>
                    </div>

                    <div className="flex gap-8 self-end mt-2">
                        {/* <Button className="text-[#C80000] font-[500]">Cancel</Button> */}
                        <Button
                            loading={sendDealToSBLoading}
                            className="text-white bg-[#00A762] px-[1rem] py-[5px] rounded-lg"
                            onClick={() => sendDealToAllLender()}
                        >
                            Send deal to all lender
                        </Button>
                    </div>
                </div>
                {showAddNewLenderToDealModal &&
                    <AddNewLenderToDeal deal={deal}
                        showAddNewLenderToDealModal={showAddNewLenderToDealModal}
                        setShowAddNewLenderToDealModal={setShowAddNewLenderToDealModal}
                    />
                }
                {showFileuploadModal &&
                    <UploadFileModal
                        getAllFiles={() => getAllFiles()}
                        deal={deal}
                        visible={showFileuploadModal}
                        onCancel={() => setShowFileuploadModal(false)}
                    />
                }
            </div>
            {isModalOpen && (
                <IndustryModal
                    industries={industries}
                    onClose={() => setModalOpen(false)}
                    onSelect={handleCompanyClick}
                    type={"Industry"}
                />
            )}

           { console.log('Ownership Values ---- ' , ownership)}
             {isOwnerModalOpen && (
                <IndustryModal
                    industries={ownership}
                    onClose={() => setOwnerModalOpen(false)}
                    onSelect={handleOwnershipClick}
                    type={"Ownership"}
                />
            )}
            <Modal
        title="Additional data"
        open={isModalForm}
        onCancel={() => setModalForm(false)}
        footer={null}
        centered
      >
      <form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={12}>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                DBA Name
              </label>
              <Input
                id="dbaName"
                name="dbaName"
                type="text"
                placeholder="Enter DBA name"
                value={formValues.dbaName}
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <label htmlFor="taxId" className="block text-sm font-medium text-gray-700">
                Tax ID
              </label>
              <Input
                id="taxId"
                name="taxId"
                type="text"
                placeholder="123456789"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={12}>
              <label htmlFor="inceptionMonth" className="block text-sm font-medium text-gray-700">
                Inception Month
              </label>
              <Input
                id="inceptionMonth"
                name="inceptionMonth"
                type="number"
                placeholder="1"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <label htmlFor="inceptionYear" className="block text-sm font-medium text-gray-700">
                Inception Year
              </label>
              <Input
                id="inceptionYear"
                name="inceptionYear"
                type="number"
                placeholder="2020"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={12}>
              <label htmlFor="requestedAmount" className="block text-sm font-medium text-gray-700">
                Requested Amount
              </label>
              <Input
                id="requestedAmount"
                name="requestedAmount"
                type="number"
                placeholder="100000.00"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State
              </label>
              <Input
                id="state"
                name="state"
                type="text"
                placeholder="Enter state"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={12}>
              <label htmlFor="naicsId" className="block text-sm font-medium text-gray-700">
                NAICS ID
              </label>
              <Input
                id="naicsId"
                name="naicsId"
                type="text"
                placeholder="NAICS Code"
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <label htmlFor="mccId" className="block text-sm font-medium text-gray-700">
                MCC ID
              </label>
              <Input
                id="mccId"
                name="mccId"
                type="number"
                placeholder="6"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={12}>
              <label htmlFor="naicsId" className="block text-sm font-medium text-gray-700">
                Owner SSN
              </label>
              <Input
                id="ownerSsn"
                name="ownerSsn"
                type="text"
                placeholder="NAICS Code"
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <label htmlFor="mccId" className="block text-sm font-medium text-gray-700">
                Owner zip
              </label>
              <Input
                id="ownerZip"
                name="ownerZip"
                type="number"
                placeholder="6"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
            </Col>
          </Row>
          {/* Fifth Row */}
          <Row gutter={16} className="mb-4">
            <Col xs={24} md={24}>
            <label htmlFor="taxId" className="block text-sm font-medium text-gray-700">
                Company Zip
              </label>
              <Input
                id="companyZip"
                name="companyZip"
                type="text"
                placeholder="123456789"
                required
                className="mt-1 block w-full"
                onChange={handleChange}
              />
              </Col>
          </Row>

          {/* Submit and Cancel Buttons */}
          <div className="flex justify-end gap-4">
            <Button
            //   onClick={handleCloseModal}
              className="bg-gray-300 text-black hover:bg-gray-400"
            >
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} style={{backgroundColor: 'green', color: 'white'}}>
            {submitLoading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </form>
      
      </Modal>
    <Modal
    className="h-80"
    title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Files ({allFilesData.length})</span>
        </div>
    }
    visible={showModal}
    onCancel={handleModalClose}
    footer={null}
    centered
    bodyStyle={{
        maxHeight: '400px', // Adjust the modal's content height
        overflowY: 'auto',  // Enable vertical scrolling
        padding: '10px',
    }}
>
    {allFilesData.length > 0 ? (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '20px',
            }}
        >
            {allFilesData.map((file , index) => (
                <div
                    key={file.fileId}
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '20px',
                        textAlign: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <h5 style={{ marginBottom: '10px', fontWeight: 'bold' }}>{file.title}</h5>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                        <a
                          onClick={() => handlePreview(index)}
                            // href={file.fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none',
                                color: 'green',
                                fontWeight: '500',
                                fontSize: '14px',
                            }}
                        >
                            View File
                        </a>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', gap: '10px', flexWrap: 'wrap' }}>
                        <Button loading={uploadFileLoading[file.fileUrl]?.['bank-statements']} onClick={() => handleUploadFundomateDoc(file.fileUrl , 'bank-statements')} className="bg-green-500 text-white">Upload as Bank Statement</Button>
                        <Button loading={uploadFileLoading[file.fileUrl]?.['merchant-application']}  onClick={() => handleUploadFundomateDoc(file.fileUrl , 'merchant-application')} type="default">Upload as Merchant Application</Button>
                        {/* <Button type="dashed">Upload as Merchant Documents</Button> */}
                    </div>
                </div>
            ))}
            {showPdfPreviewModal && selectedPdfIndex !== null && (
                <PdfPreviewModal
                    visible={showPdfPreviewModal}
                    onCancel={() => setShowPdfPreviewModal(false)}
                    pdfIndex={selectedPdfIndex}
                    allPdfFiles={allFilesData}
                    setSelectedPdfFile={setSelectedPdfIndex}
                />
            )}
        </div>
    ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <p>No files available.</p>
        </div>
    )}
</Modal>



        </>
    )
}

// {
//     "clientId": "261003@fundomate.id",
//     "clientSecret": "F2!sGIEfdXCbR"
//   }

// eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJRbWRHUzM5WmdxMjYzTEdONE5HbDM0Y2JocE1XRWgxQlJsZ1Bzd3BCbU5RIn0.eyJleHAiOjE3MzY3NzM3NzUsImlhdCI6MTczNjc2NjU3NSwianRpIjoiMWEwYTAxM2ItZTljYS00ZWFmLTkzNDMtYTM0NzIzY2U5MTUxIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5zdGcuZnVuZG9tYXRlLnh5ei9hdXRoL3JlYWxtcy9wYXJ0bmVyIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjVlMTk4NmVlLTk0N2EtNGRjZS1hM2MyLTdkY2ExM2E3ZTNlYSIsInR5cCI6IkJlYXJlciIsImF6cCI6InBhcnRuZXItYXBpLWd3Iiwic2lkIjoiZWE5N2EwZTYtMzZhMi00OTEwLWE4ZDUtM2JkNjk1ZDBjMzg4IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vbG9jYWxob3N0OjgwODAiLCJodHRwczovL3BhcnRuZXItY3JtLWFwaS5zdGcuZnVuZG9tYXRlLnh5eiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1wYXJ0bmVyIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIGVtYWlsIHByb2ZpbGUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6IjI2MTAwM0BmdW5kb21hdGUuaWQiLCJlbWFpbCI6IjI2MTAwM0BmdW5kb21hdGUuaWQifQ.dQRyGIiJEsYQc76M2nm5XNEMNLT_2NO2fCLusQEHF9RnhrDFZRiJQFk0EQeDrbjQagr1t33eH2XoBK0lx2HtXCZ5-_NwzFVDTmyFbI-ilSK6_e1HsKE7MpvQ1pomx_1ADU68LoQBZV75J9VlBw2vqEddCoY2ezvdmhGqT2QcMIrX3cTBuut61na55s8ExagjkDaTRKqcxRxeeZtgDkECpDcyC9sU6cNbyvTpplkMKM5zqORyLnjicHX8bX_2iyic9xNTQSiycG3YhN4slJItc2K-J2qYbkWJrx40AExrGPI9syeQLXqS2WGM7vXT3X1SHKSjGqT5KAKz1hziUoIeIA