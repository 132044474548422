import axios from "axios";
import APIUrl from "../../../../../atom/APIUrl";

export const authFundomate = async () => {
    try {
        const response = await fetch(`${APIUrl}/fundomate/auth-fundomate`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     clientId: "261003@fundomate.id",
            //     clientSecret: "F2!sGIEfdXCbR"
            // }),
        });

        const data = await response.json();

        if (data.accessToken) {
            return data.accessToken; // Return the token for further API calls
        } else {
            console.error("Authentication failed:", data);
            throw new Error(data.message || "Authentication failed");
        }
    } catch (error) {
        console.error("Error during authentication:", error);
        throw error;
    }
};

export const getIndustry = async () => {
    const url = `${APIUrl}/fundomate/industries`;
    const token = await authFundomate();
    console.log("Auth Tokenpp ---- " , token)

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                token: token,
            }),
        });
        const data = await response.json();
        console.log("Industry Data ----", data);
        return data;
    } catch (error) {
        console.error("Error fetching industry data:", error);
        throw error;
    }
};

export const getOwnershipValues = async () => {
    const url = `${APIUrl}/fundomate/ownership-values`;
    const token = await authFundomate();

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
            }),
        });
        const data = await response.json();
        console.log("Ownership Data ----", data);
        return data;
    } catch (error) {
        console.error("Error fetching industry data:", error);
        throw error;
    }
};

export const createDeal = async (dealData) => {
    try {
        const token = await authFundomate();
        const config = {
            method: 'post',
            url: `${APIUrl}/fundomate/create-deal`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                token : token,
                data : dealData}),
        };
        const response = await axios.request(config);
        console.log("Deal creation successful:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error during deal creation:", error);
        throw error;
    }
};

export const createFundomateDeal = async (dealData) => {

    console.log("Called  createFundomate ")
    const url = `${APIUrl}/fundomate/create-deal`;
    const token = await authFundomate();

    console.log("Token  create Fundomate --- " , token)

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token : token,
                data : dealData}),
        });

        console.log("Fundomate response --- " , response.body)

        if (!response.ok) {
            throw new Error(`Failed to create deal: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Created Deal Data ----", data);
        return data;
    } catch (error) {
        console.error("Error creating Fundomate deal:", error);
        throw error;
    }
};

const dealData = {
    companyEmail: "user12@example.com",
    companyName: "Example Corp",
    dbaName: "Example DBA",
    companyAddress: "123 Main St",
    companyCity: "Example City",
    companyState: "CA",
    companyZip: "12345",
    companyPhone: "+11234567890",
    taxId: "123456789",
    inceptionMonth: 1,
    inceptionYear: 2020,
    requestedAmount: 100000,
    industry: "Retail",
    naicsId: "",
    mccId: 6,
    ownershipType: "SOLE_PROPRIETOR",
    owners: [
        {
            ownerFirstName: "John",
            ownerLastName: "Doe",
            ownerAddress: "456 Elm St",
            ownerCity: "Another City",
            ownerState: "NY",
            ownerZip: "67890",
            ownerPhone: "+11234567891",
            ownerSsn: "123-45-6789",
            ownerDateOfBirth: "10-22-2000",
        },
    ],
};

export const uploadBankStatementFromUrl = async (dealProfileId, fileUrl , endPoint) => {
    try {
        console.log("Calling function --- Deal Profile Id:", dealProfileId, "File URL:", fileUrl);

        const token = await authFundomate();
        console.log("Token retrieved:", token);

        // const fileResponse = await axios.get(fileUrl, { responseType: 'arraybuffer' });

        // const contentType = fileResponse.headers['content-type'];
        // console.log("File content type:", contentType);

        // const fileBlob = new Blob([fileResponse.data], { type: contentType });

        // Create FormData and append the Blob
        // const formData = new FormData();
        // formData.append('file', fileBlob, 'bank-statement.pdf'); // Use a descriptive file name

        // Set up the request configuration
        const config = {
            method: 'POST',
            url: `${APIUrl}/fundomate/upload-files-endpoint`,
            headers: {
                'Accept': 'application/json',
            },
            data: 
                {
                    dealProfileId : dealProfileId,
                    fileUrl : fileUrl,
                    endPoint : endPoint,
                    token : token
                },
        };

        // Send the request
        const response = await axios.request(config);
        console.log('Bank statement upload successful:', response.data);
        return response.data;

    } catch (error) {
        // Log detailed error information
        if (error.response) {
            console.error("API responded with an error:", error.response.data);
        } else {
            console.error("Error during bank statement upload:", error.message);
        }
        throw error;
    }
};

createDeal(dealData)
    .then(data => console.log("Final Response:", data))
    .catch(error => console.error("Error:", error));
