import React, { useEffect, useState } from 'react';
import { FaRegFolder, FaDownload } from 'react-icons/fa';
import { MdUploadFile } from "react-icons/md";
import { Input, Spin, Upload, Button, Modal } from 'antd'; // Added Modal import
import { useDispatch, useSelector } from 'react-redux';
import { getAllFilesAction, resetAllFilesAction, uploadFileAction } from '../../../../redux/actions/leadsActions';
import { toast } from 'react-toastify';
import PdfPreviewModal from '../../DealsScreen/InduvidualDealTabs/AgentDealLender/PdfPreviewModal';
import PDF from "react-pdf-js";

const { Dragger } = Upload;

const FilesTab = ({ deal }) => {
  const [files, setFiles] = useState([]);
  const [renamedFileNames, setRenamedFileNames] = useState({});
  const [allFilesArr, setAllFilesArr] = useState([]);
  const { userId } = useSelector((state) => state.auth);
  const [showPdfPreviewModal, setShowPdfPreviewModal] = useState(false);
  const [selectedPdfFile, setSelectedPdfFile] = useState(null);
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [showUploadModal, setShowUploadModal] = useState(false); // Added state for modal visibility

  const dispatch = useDispatch();

  const { loading: uploadLoading } = useSelector(state => state.uploadFile);
  const { loading: allFilesLoading } = useSelector(state => state.getAllFiles);

  const getAllFiles = async () => {
    const result = await dispatch(getAllFilesAction(userId, deal?._id));
    if (result?.status === 1) {
      setAllFilesArr(result?.files);
    } else {
      setAllFilesArr([]);
    }
  };

  useEffect(() => {
    dispatch(resetAllFilesAction());
    getAllFiles();
  }, [deal]);

  const uploadProps = {
    onRemove: (file) => {
      setFiles((prev) => prev.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const isPdfFormat = /\.pdf$/.test(file.name);
      if (isPdfFormat) {
        setFiles((prev) => [...prev, file]);
        setShowUploadModal(true); // Show modal when a file is added
      } else {
        toast.error('Please upload files in .pdf format');
      }
      return false;
    },
    multiple: true,
    fileList: files,
    accept: ".pdf",
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('lead_id', deal?._id);

      files.forEach((file) => {
        const renamedFileName = renamedFileNames[file.uid] || file.name;
        formData.append('title', renamedFileName);
        formData.append('files', file);
      });

      const result = await dispatch(uploadFileAction(userId, formData));
      if (result?.status === 1) {
        setFiles([]);
        setRenamedFileNames({});
        toast.success(result?.message);
        getAllFiles();
        setShowUploadModal(false); // Close modal after successful upload
      } else {
        toast.error(result?.message);
      }
    } else {
      toast.error('Please select at least one file to upload.');
    }
  };

  const handleRenameChange = (file, value) => {
    setRenamedFileNames((prev) => ({ ...prev, [file.uid]: value }));
  };

  const handlePreview = (fileUrl) => {
    setSelectedPdfFile(fileUrl);
    setShowPdfPreviewModal(true);
  };

  const onDocumentComplete = (numPages) => {
    setPages(numPages);
  };

  const onDocumentError = (err) => {
    console.error("pdf viewer error:", err);
  };

  return (
    <div className="px-4 h-full">
      <div className="flex justify-center items-center bg-white rounded-3xl w-52 p-2">
        <FaRegFolder className="text-blue-500 mr-4" size={24} />
        <a href="https://googledrive.com" target="_blank" rel="noopener noreferrer" className="text-blue-700 font-semibold">
          googledrive.com
        </a>
      </div>
      <div className="w-full h-[85%] flex flex-col justify-between">
        <Spin spinning={allFilesLoading}>
          <div className="flex flex-wrap gap-8 max-h-[60vh] mt-7 overflow-y-auto">
            {allFilesArr && allFilesArr.map((file, index) => (
              <div key={index} className="h-72">
                <div
                  className="w-48 h-72 bg-white rounded-md overflow-hidden cursor-pointer"
                  onClick={() => handlePreview(index)}
                >
                  <PDF
                    file={file?.fileUrl}
                    onDocumentComplete={onDocumentComplete}
                    onDocumentError={onDocumentError}
                    page={1}
                    scale={scale}
                  />
                </div>
                <a href={file?.fileUrl} className="inline-block text-custom-green cursor-pointer w-10">
                  <FaDownload />
                </a>
              </div>
            ))}
            <div className="w-48 h-72">
              <Dragger {...uploadProps}>
                <span className="text-primary text-6xl flex justify-center">
                  <MdUploadFile size={40} color="#00A762" />
                </span>
                <p className="ant-upload-text font-semibold py-3">Add documents</p>
              </Dragger>
            </div>
          </div>
        </Spin>
        {showPdfPreviewModal && (
          <PdfPreviewModal
            visible={showPdfPreviewModal}
            onCancel={() => setShowPdfPreviewModal(false)}
            pdfIndex={selectedPdfFile}
            allPdfFiles={allFilesArr}
            setSelectedPdfFile={setSelectedPdfFile}
          />
        )}
      </div>

      <Modal
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        footer={null}
        title="Upload Files"
      >
        <div>
          {files.map((file) => (
            <div key={file.uid} className="flex justify-between items-center mt-2">
              <Input
                placeholder="Rename the file"
                className="w-[80%]"
                suffix=".pdf"
                onChange={(e) => handleRenameChange(file, e.target.value)}
              />
            </div>
          ))}
          {files.length > 0 && (
            <Button
              onClick={handleUpload}
              loading={uploadLoading}
              className="bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer mt-3"
            >
              Upload All
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FilesTab;
