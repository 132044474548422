import * as yup from "yup"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Input, Row, Select, Spin } from 'antd'
import { useRecoilState } from 'recoil'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import { useEffect, useState } from 'react'
import { getAllLeadsAction } from '../../../redux/actions/leadsActions'
import { getQueuedLeadsAction, postComplianceFormAction } from '../../../redux/actions/complianceAction'

const schema = yup
  .object({
    companyName: yup
      .string()
      .max(100)
      .required("Select company name"),
    // .matches(/^[a-zA-Z\s]+$/, "First Name should only contain alphabets"),
    firstName: yup
      .string()
      .max(100)
      .required("Enter first name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter Last Name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    phoneNumber: yup.string().required("Mobile Number Must Be 10 Digits"),
    contract_amount: yup.string().required("Contract amount is required"),
    payment_amount: yup.string().required("payment amount is required"),
    leadTag: yup
      .string()
      .max(100)
      .required("Lead Id is required"),
  })
  .required()

const defaultValues = {
  companyName: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  contract_amount: "",
  payment_amount: "",
  leadTag: "",
}

const ComplianceForm = () => {
  const [companiesOption, setCompaniesOption] = useState([])
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  const { loading: allLeadsLoading, allLeads } = useSelector(state => state.getAllLeads);
  const { loading: postComplianceFormLoading } = useSelector(state => state.postComplianceForm);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const companyNameWatcher = watch("companyName")

  useEffect(() => {
    if (companyNameWatcher) {
      const selectedCompany = allLeads?.find(lead => lead?._id === companyNameWatcher)
      setValue("firstName", selectedCompany?.firstName)
      setValue("lastName", selectedCompany?.lastName)
      setValue("email", selectedCompany?.email)
      setValue("phoneNumber", selectedCompany?.phone)
      setValue("leadTag", selectedCompany?.tag)
    }
  }, [companyNameWatcher])

  useEffect(() => {
    if (allLeads?.length > 0) {
      let companies = []
      allLeads?.forEach(lead => {
        companies.push({
          value: lead?._id,
          label: lead?.company_name
        })
      })
      setCompaniesOption(companies)
    }
  }, [allLeads])

  useEffect(() => {
    dispatch(getAllLeadsAction(userId))
  }, [])

  const onSubmit = async data => {

    console.log("onsubmit clicked --- ")
    const formattedData = {
      lead_id: data?.companyName,
      payment_amount: '0',
      contract_amount: '0'
    }
    const result = await dispatch(postComplianceFormAction(userId, formattedData));
    if (result?.status === 1) {
      toast.success("Lead submitted successfully")
      dispatch(getQueuedLeadsAction(userId));
      reset()
    } else {
      toast.error(result?.message)
    }
  }

  return (
    <div className="flex">
      <form className="flex flex-col justify-start" onSubmit={handleSubmit(onSubmit)}>
        {/* <Spin spinning={true}> */}
        <Row gutter={16}>
          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-companyName">
              Company Name
            </label>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size='large'
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="label"
                  notFoundContent={allLeadsLoading ? <Spin size="small" /> : null}
                  status={errors.companyName ? "error" : undefined}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={companiesOption}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            {errors.companyName ? (
              <small className="text-red-500 capitalize">{errors.companyName.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-firstName">
              First Name
            </label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-firstName"
                  disabled
                  placeholder='Enter First Name'
                  status={errors.firstName ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.firstName ? (
              <small className="text-red-500 capitalize">{errors.firstName.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-lastName">
              Last Name
            </label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-lastName"
                  disabled
                  placeholder='Enter Last Name'
                  status={errors.lastName ? "error" : undefined}
                  {...field}
                // onChange={e => field.onChange(e)}
                />
              )}
            />
            {errors.lastName ? (
              <small className="text-red-500 capitalize">{errors.lastName.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-email">
              Email
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  type="email"
                  id="input-email"
                  disabled
                  placeholder='Enter Email'
                  status={errors.email ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.email ? (
              <small className="text-red-500 capitalize">{errors.email.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-phoneNumber">
              Phone Number
              {/* <span className="text-red-500">*</span> */}
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                <Input
                  size="large"
                  id="input-phoneNumber"
                  disabled
                  placeholder='Enter Phone Number'
                  {...field}
                  status={errors.phoneNumber ? "error" : undefined}
                />}
            />
            {errors.phoneNumber ? (
              <small className="text-red-500 capitalize">{errors.phoneNumber.message}</small>
            ) : null}
          </Col>


          {/* <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-paymentAmount">
              Payment Amount
            </label>
            <Controller
              control={control}
              name="payment_amount"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-paymentAmount"
                  placeholder='Enter payment amount'
                  status={errors.payment_amount ? "error" : undefined}
                  {...field}
                  onChange={e => /^[0-9\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.payment_amount ? (
              <small className="text-red-500 capitalize">{errors.payment_amount.message}</small>
            ) : null}
          </Col> */}
          <Col className="mb-4" xs={24} md={24} lg={24}>
            <label className="mb-1 font-medium" htmlFor="input-leadTag">
              Lead ID
            </label>
            <Controller
              name="leadTag"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-leadTag"
                  placeholder='Enter Lead Tag'
                  status={errors.leadTag ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.leadTag ? (
              <small className="text-red-500 capitalize">{errors.leadTag.message}</small>
            ) : null}
          </Col>

        </Row>
        {/* </Spin> */}
        <div className='flex justify-center items-center gap-4 mb-5'>
          <Button
            htmlType="submit"
            loading={postComplianceFormLoading}
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Submit for removal
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ComplianceForm