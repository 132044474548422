// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../data/Group 536.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmissionsAction, setShownOldDealsButton } from '../../../redux/actions/submissionBoardAction';
import { Button, Spin, DatePicker, Modal } from 'antd';
import moment from 'moment';
import OldDealsTable from './OldDealsTable';
import { FaCircle } from 'react-icons/fa';
import { HorizontalFilter } from "../../../common/components/HorizontalFilter";
import { changeSubmissionBoardStatus } from "../../../atom/authuserData/userdata";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;

const SubmissionTable = () => {
  const [submissionsTableData, setSubmissionsTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [showOldDeals, setShowOldDeals] = useState(false);
  const [status, setStatus] = useState('');
  const { userId } = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
const [selectedStatus, setSelectedStatus] = useState('');
const [rowData, setRowData] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { role } = useSelector((state) => state.auth);
  const { loading: submissionsLoading, submissions } = useSelector(state => state.getAllSubmissions);
  const { showOldDeals: showOldDealsButton } = useSelector(state => state.setShownOldDealsButton);

  useEffect(() => {
    dispatch(getAllSubmissionsAction(userId));
  }, []);

  useEffect(() => {
    setShowOldDeals(showOldDealsButton);
  }, [showOldDealsButton]);

  const handleShowOldDeals = () => {
    setShowOldDeals(!showOldDeals);
    dispatch(setShownOldDealsButton(!showOldDeals));
  }
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  
  const handleStatusChange = async (newStatus) => {
    const response = await changeSubmissionBoardStatus(userId , rowData?.key , newStatus )
    
    if(response?.status == 1){
    setIsModalVisible(false);
     dispatch(getAllSubmissionsAction(userId));
    }

    toast.success(response?.message)
  };
  

  useEffect(() => {
    let filteredData = []
    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = submissions.filter((submission) => {
        const agentName = `${submission?.firstName} ${submission?.lastName}`;
        return (
          submission?.agent_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.company_name.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.tag.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          submission?.lead_source.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          agentName.toLowerCase().includes(trimmedSearchText.toLowerCase())
        )
      });
    } else {
      filteredData = submissions;
    }

    if (status && status !== 'All') {
      filteredData = filteredData?.filter((deal) => deal.status === status);
    }

    if (dateRange?.length === 2) {
      const [startDate, endDate] = dateRange;

      // Convert start and end dates to moment objects in UTC
      const startMoment = (startDate);
      const endMoment = (endDate);

      console.log('Sending Starting and end Date  start Date ', startMoment.format(), 'End Date ', endMoment.format())

      filteredData = filteredData?.filter((deal) => {
        // Convert deal.createdAt to a moment object in UTC
        const dealDate = moment(deal?.createdAt).utc();
        console.log("Deal Date: ", dealDate?.toISOString());

        // Compare dealDate with the range [startMoment, endMoment]
        return dealDate.isBetween(startMoment?.format(), endMoment?.format(), null, '[]');  // '[]' includes the boundary dates
      });

      console.log("Filtered Data: ", filteredData);
    }

    const submissionData = []
    if (filteredData?.length > 0) {
      filteredData.forEach((submission) => {
        submissionData.push({
          key: submission?._id,
          status: submission?.status,
          agent: submission?.agent_name,
          company: submission?.company_name,
          date: submission?.createdAt,
          leadId: submission?.tag,
          leadSource: submission?.lead_source,
          lenders: 'Update Lenders',
          openDeal: submission,
          createdAt: submission?.createdAt,
          comment: submission?.comment,
        });
      });
    }

    setSubmissionsTableData(submissionData?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [submissions, searchText, status, dateRange]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = (dates[0]);
      const endDate = (dates[1]);

      console.log("Start Date: ", startDate, "End Date: ", endDate);
      setDateRange([startDate, endDate]);
    } else {
      console.log("Invalid dates received, resetting range.");
      setDateRange([]);
    }
  };

  const handleStatusClick = (status, row) => {
    if(role != 'agents'){
    setSelectedStatus(status);
    setRowData(row);
    setIsModalVisible(true);
    }
  };


  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row }) => (
          <div
            onClick={() => handleStatusClick(value, row.original)} // Trigger modal on click
            className={`flex items-center justify-center space-x-2 px-2 py-1  ${
              value === 'Declined'
                ? 'bg-declined-bgcolor'
                : value === 'Approved'
                ? 'bg-operation-green'
                : value === 'Documents Sent'
                ? 'bg-blue-100'
                : value === 'Killed'
                ? 'bg-declined-bgcolor'
                : value === 'Docs Requested'
                ? 'bg-green-200'
                : 'bg-pending-background'
            } text-white rounded-2xl border w-28 cursor-pointer hover:shadow-lg`}
          >
            <span
              className={`h-2 w-2 ${
                value === 'Declined'
                  ? 'bg-red-600'
                  : value === 'Approved'
                  ? 'bg-green-500'
                  : value === 'Documents Sent'
                  ? 'bg-blue-400'
                  : value === 'Killed'
                  ? 'bg-red-700'
                  : value === 'Docs Requested'
                  ? 'bg-green-700'
                  : 'bg-pendingText'
              } rounded-full`}
            ></span>
            <span
              className={`${
                value === 'Declined'
                  ? 'text-red-600'
                  : value === 'Approved'
                  ? 'text-approved-text'
                  : value === 'Documents Sent'
                  ? 'text-blue-500'
                  : value === 'Killed'
                  ? 'text-red-700'
                  : value === 'Docs Requested'
                  ? 'text-green-700'
                  : 'text-pendingText'
              } font-semibold`}
            >
              {value}
            </span>
          </div>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center">
            <span className="text-good-morning-text font-semibold">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Company',
        accessor: 'company',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center">
            {value}
          </div>
        ),
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center">
            <span>{moment(value).format('MM/DD/YYYY')}</span>
          </div>
        ),
      },
      {
        Header: 'Lead ID',
        accessor: 'leadId',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center mr-10">{value}</div>
        ),
      },
      {
        Header: 'Lead Source',
        accessor: 'leadSource',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center">{value}</div>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comment',
        Cell: ({ value }) => (
          <div className="w-[150px] flex items-center justify-center">
            {value || 'No comment yet'}
          </div>
        ),
      },
      {
        Header: 'Open deal',
        accessor: 'openDeal',
        Cell: ({ value }) => (
          <div className="flex items-center justify-center ">
            <FiEye
              onClick={() =>
                navigate('/dealtabs', { state: { deal: value } })
              }
              alt="Open Deal"
              style={{
                cursor: 'pointer',
                width: '20px',
                height: '20px',
              }}
            />
          </div>
        ),
      },
    ],
    []
  );
  

  const options = [
    {
      label: (
        <span className="text-black flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          All
        </span>
      ),
      value: 'All',
    },
    {
      label: (
        <span className="text-green-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Approved
        </span>
      ),
      value: 'Approved',
    },
    {
      label: (
        <span className="text-orange-300 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Pending
        </span>
      ),
      value: 'Pending',
    },
    {
      label: (
        <span className="text-red-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Declined
        </span>
      ),
      value: 'Declined',
    },

    {
      label: (
        <span className="text-blue-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Documents Sent
        </span>
      ),
      value: 'Documents Sent',
    },
    // {
    //   label: (
    //     <span className="text-yellow-500 flex justify-start items-center gap-2">
    //       <FaCircle size="10px" />
    //       Funded
    //     </span>
    //   ),
    //   value: 'Funded',
    // },
    {
      label: (
        <span className="text-red-700 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Killed
        </span>
      ),
      value: 'Killed',
    },
    {
      label: (
        <span className="text-green-700 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Docs Requested
        </span>
      ),
      value: 'Docs Requested',
    },
  ];

  const handleStatusChanges = (value) => {
    console.log("Habndle hit === ", value)
    setStatus(value);
  }


  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        {(role === "admin" || role === "opAdmin") &&
          <div className='flex justify-end items-center gap-2 mt-3'>
            {/* {showOldDeals &&
              <Select
                size='medium'
                allowClear
                style={{ width: "150px", height: "36px" }}
                options={options}
                placeholder="Select status"
                suffixIcon={<DownOutlined />}
                onChange={e => handleStatusChange(e)}
              />
            } */}
            <Button
              onClick={() => handleShowOldDeals()}
              className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
            >
              {showOldDeals ? 'Hide old deals' : 'Show old deals'}
            </Button>
          </div>
        }
        {showOldDeals ? <OldDealsTable status={status} /> :
          <div className="flex-grow overflow-y-auto mx-4 mb-5">
            {submissionsLoading ?
              <div className='w-full pt-16 flex justify-center items-center' >
                <Spin />
              </div>
              :
              <div className='mt-4'>
                <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                  <div className="flex flex-row justify-center items-center">
                    <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                      <img
                        src={searchIcon}
                        alt="Search Icon"
                        className="w-8 h-8 mr-2"
                      />
                      <input
                        type="text"
                        placeholder="Search information"
                        className="bg-background-color focus:outline-none pl-3 w-full"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <RangePicker
                      className="text-black font-bold"
                      onChange={(dates) => handleDateChange(dates)}
                    />
                  </div>
                  <HorizontalFilter options={options} onStatusChange={handleStatusChanges} />
                </div>
                <ReusableTable columns={columns} data={submissionsTableData} />
              </div>
            }
             <Modal
      title="Change Status"
      visible={isModalVisible}
      onCancel={handleModalClose}
      footer={null}
    >
      <div className="space-y-7 mx-2">
        <Button className="bg-green-500 w-1/2 text-white"
          onClick={() => handleStatusChange('Killed')}
        >
          Killed
        </Button >
        <Button className="bg-green-500 w-1/2 text-white"
          onClick={() => handleStatusChange('Docs Requested')}
        >
          Docs Requested
        </Button>
        <Button className="bg-green-500 w-1/2 text-white"
          onClick={() => handleStatusChange('Approved')}
        >
          Approved
        </Button>
        <Button className="bg-green-500 w-1/2 text-white"
          onClick={() => handleStatusChange('Pending')}
        >
          Pending
        </Button>
      </div>
    </Modal>
          </div>
        }
      </main>
    </div>
  );
};

export default SubmissionTable;
