import React from "react";

const IndustryModal = ({ industries, onClose, onSelect, type }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-96 max-h-[80vh] overflow-hidden">
                <h2 className="text-lg font-bold mb-4">Select {type}</h2>
                <ul className="space-y-2 overflow-y-auto max-h-64">
                    {industries?.map((industry) => (
                        <li
                            key={industry}
                            className="cursor-pointer p-2 bg-gray-100 hover:bg-gray-200 rounded"
                            onClick={() => onSelect(industry)}
                        >
                            {industry.replace(/_/g, " ")}
                        </li>
                    ))}
                </ul>
                <button
                    className="mt-4 w-full bg-red-500 text-white py-2 rounded hover:bg-red-600"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default IndustryModal;
